var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.isLoading
    }
  }, [_vm.isRetail ? _c('BCard', {
    staticClass: "px-1 py-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('h4', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" Vui lòng chọn đại lý bán lẻ: ")]), _c('SelectChildAgency', {
    staticStyle: {
      "max-width": "500px"
    },
    attrs: {
      "id": "agency-code",
      "agency-select": _vm.agencySelect,
      "clearable": false,
      "placeholder": _vm.$t('saleReport.phAgencyCode')
    },
    on: {
      "update:agencySelect": function updateAgencySelect($event) {
        _vm.agencySelect = $event;
      },
      "update:agency-select": function updateAgencySelect($event) {
        _vm.agencySelect = $event;
      }
    }
  })], 1) : _vm._e(), _vm.employeeId ? _c('b-tabs', {
    staticClass: "justify-content-between",
    attrs: {
      "pills": "",
      "title-link-class": "font-medium-1 font-weight-bolder mb-1",
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "tabs-end",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-end flex-fill"
        }, [_c('BButton', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-create-profile",
            modifiers: {
              "modal-create-profile": true
            }
          }],
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('fee.create.buttonTitle')) + " ")])], 1)];
      },
      proxy: true
    }], null, false, 1352274976)
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "MapPinIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v(_vm._s(_vm.$t('fee.domesticFee')))])];
      },
      proxy: true
    }], null, false, 3006772647)
  }, [_c('ServiceFeeTabProfiles', {
    attrs: {
      "ticket-type": "INLAND",
      "fee-services-data-props": _vm.feeServicesDataInland,
      "employee-id": _vm.employeeId
    },
    on: {
      "getFeeConfigsData": _vm.getFeeConfigsData
    }
  })], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "NavigationIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v(_vm._s(_vm.$t('fee.internationalFee')))])];
      },
      proxy: true
    }], null, false, 3825951154)
  }, [_c('ServiceFeeTabProfiles', {
    attrs: {
      "ticket-type": "INTERNATIONAL",
      "fee-services-data-props": _vm.feeServicesDataInternational,
      "employee-id": _vm.employeeId
    },
    on: {
      "getFeeConfigsData": _vm.getFeeConfigsData
    }
  })], 1)], 1) : _vm._e(), _c('CreateProfileModal', {
    attrs: {
      "employee-id": _vm.employeeId
    },
    on: {
      "getFeeConfigsData": _vm.getFeeConfigsData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }