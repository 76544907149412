import { apiEmployee } from '@/api/'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getEmployeeProfile(ctx, employeeId) {
      return apiEmployee.getEmployeeProfile(employeeId)
    },

    createEmployeeProfile(ctx, { employeeId, payload }) {
      return apiEmployee.createEmployeeProfile(employeeId, payload)
    },

    deleteEmployeeProfile(ctx, { employeeId, employeeProfileId }) {
      return apiEmployee.deleteEmployeeProfile(employeeId, employeeProfileId)
    },

    getServiceFee(ctx, employeeId) {
      return apiEmployee.getServiceFee(employeeId)
    },

    createServiceFee(ctx, { employeeId, payload }) {
      return apiEmployee.createServiceFee(employeeId, payload)
    },

    createServiceFeeMany(ctx, { employeeId, payload }) {
      return apiEmployee.createServiceFeeMany(employeeId, payload)
    },
  },
}
