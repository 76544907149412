/* eslint-disable no-unused-vars */
import { ref } from '@vue/composition-api'

import store from '@/store'

import useToast from '@useToast'

export default function useServiceFeeHandle() {
  const { toastError, toastSuccess } = useToast()
  const loading = ref(false)

  const tableColumns = ref([
    { label: 'airline', key: 'airline' },
    { label: 'feeType', key: 'feeType' },
    { label: 'adultAmount', key: 'adultAmount' },
    { label: '', key: 'applyAll' },
    { label: 'childAmount', key: 'childAmount' },
    { label: 'infantAmount', key: 'infantAmount' },
    // { label: 'updatedAt', key: 'updatedAt' },
    { label: 'action', key: 'action' },
  ])

  const getEmployeeProfile = id => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-service-fee/getEmployeeProfile', id)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const createEmployeeProfile = (employeeId, payload) => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-service-fee/createEmployeeProfile', { employeeId, payload })
      .then(res => {
        resolve(res)
        toastSuccess({ title: 'messagesList.success', content: 'Tạo cấu hình phí dịch vụ thành công!' })
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: `${error.message || error}`,
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  const activeDefaultEmployeeProfile = (employeeId, payload) => new Promise((resolve, reject) => {
    loading.value = true
    payload.isDefault = true
    store
      .dispatch('app-service-fee/createEmployeeProfile', { employeeId, payload })
      .then(res => {
        resolve(res)
        toastSuccess({ title: 'messagesList.success', content: 'Đặt làm mặc định thành công!' })
        store.dispatch('userStore/fetchMeData') // fetch lại Me
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: `${error.message || error}`,
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  const createServiceFeeMany = (employeeId, payload) => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-service-fee/createServiceFeeMany', { employeeId, payload })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: `${error.message || error}`,
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  const deleteEmployeeProfile = (employeeId, employeeProfileId) => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-service-fee/deleteEmployeeProfile', { employeeId, employeeProfileId })
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'Xoá cấu hình phí dịch vụ thành công',
        })
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: `${error.message || error}`,
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  return {
    loading,
    tableColumns,
    getEmployeeProfile,
    createEmployeeProfile,
    createServiceFeeMany,
    deleteEmployeeProfile,
    activeDefaultEmployeeProfile,
  }
}
